import React, { useState, useEffect } from "react"
import "./App.css"
import axios from "axios"
import { loadStripe } from "@stripe/stripe-js"

// Replace with your Stripe public key
const stripePromise = loadStripe(
  "pk_live_51P1risL8Wr8sPxEaPCApYa5Czc8l3QXDjsYdCJo6YUynfzPIGEgQlI6rABF4w7xzewKgZ5TQd6hnMzkMSi9XRr1t00SMXAMkSY"
)

function App() {
  const [list, setList] = useState([])
  const [currentPrice, setCurrentPrice] = useState(1)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const session_id = queryParams.get("session_id")

    if (session_id) {
      console.log("Found session ID:", session_id)
      // Assuming verifyPayment is an async function that makes an API call to your backend
      verifyPayment(session_id)
        .then(() => {
          console.log("Verified payment for session:", session_id)
          // Optionally, clear the query parameters from the URL after processing
          window.history.pushState(null, "", window.location.pathname)
        })
        .catch((error) => {
          console.error("Failed to verify payment:", error)
        })
    } else {
      console.log("No session ID found in URL.")
    }

    fetchList()
  }, [])

  const fetchList = async () => {
    try {
      const response = await axios.get(
        "https://b0gk0w071m.execute-api.us-east-1.amazonaws.com/dev/getitems"
      )
      const sortedItems = response.data.items.sort((a, b) => b.price - a.price)
      setList(sortedItems)

      if (sortedItems.length > 0) {
        const highestPrice = sortedItems[0].price
        setCurrentPrice(highestPrice + 1)
      }
    } catch (error) {
      console.error("Error fetching list:", error)
    }
  }

  const initiateCheckout = async (name) => {
    const stripe = await stripePromise
    try {
      const response = await axios.post(
        "https://b0gk0w071m.execute-api.us-east-1.amazonaws.com/dev/create-checkout-session",
        { price: currentPrice * 100, name }
      )

      if (response.status === 200) {
        const { sessionId } = response.data
        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({ sessionId })
        if (result.error) {
          alert(result.error.message)
        }
      }
    } catch (error) {
      console.error("Error initiating checkout:", error)
      if (error.response && error.response.status === 409) {
        // Handle the 409 conflict error specifically
        alert(
          error.response.data.error ||
            "This spot is currently reserved. Please wait a few minutes and try again."
        )
      } else {
        // Handle other errors
        alert("Failed to initiate payment. Please try again.")
      }
    }
  }

  const verifyPayment = async (sessionId) => {
    // Call backend to verify payment success and finalize spot addition
    const response = await axios.post(
      "https://b0gk0w071m.execute-api.us-east-1.amazonaws.com/dev/verifyPayment",
      { session_id: sessionId }
    )
    if (response.data.success) {
      console.log("Success")
      fetchList() // Refresh the list to show the new addition
    } else {
      alert("Payment verification failed. Please contact support.")
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const name = event.target.elements.name.value
    initiateCheckout(name) // Directly initiate checkout
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>The Fuck You Money List</h1>
        <p>It's simple pay to be at the top. Why?</p>
        <p>
          To say fuck you to everyone either not on the list or below you,
          that's why. If you can't afford it don't worry someone else can.
        </p>
        <p>
          Every rung on this ladder costs exactly that in dollars. Spot 10?
          That’s $10. Spot 11? $11. And on it climbs, it'll never be cheaper
          than right now.
        </p>
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            maxLength="20"
            required
          />
          <button type="submit">Join for ${currentPrice}</button>
        </form>
        <div>
          <h2>Current Price: ${currentPrice}</h2>

          <ul>
            {list.map((item, index) => (
              <li
                key={index}
                style={
                  index === 0
                    ? { fontWeight: "bold", fontSize: "larger", padding: 15 }
                    : {}
                }
              >
                {`${list.length - index}. ${item.name} - $${item.price}`}
              </li>
            ))}
          </ul>
        </div>
      </header>
    </div>
  )
}

export default App
